







































































import Vue from 'vue'
import { UserUpgradesInterface } from '@/types/models/UserUpgradesInterface'
import { FiltersVerificationsInterface } from '@/types/api/FiltersVerificationsInterface'
import { UserUpgradeStateSelect, UserUpgradeState } from '@/utils/enum/UserUpgradeState'
import dataTableMixin from '@/mixins/dataTable'
import formattingMixin from '@/mixins/formatting'
import UserUpgradesService from '@/services/userUpgrades'
import DebounceSearchComponent from '@/components/DebounceSearch.vue'

export default Vue.extend({
  name: 'VerificationsPage',
  components: { DebounceSearchComponent },
  mixins: [dataTableMixin, formattingMixin],
  data: () => ({
    UserUpgradeState: UserUpgradeState,
    StatusList: UserUpgradeStateSelect,
    headers: [
      { text: 'Username', value: 'user.username', sortable: false },
      { text: 'Phone', value: 'user.phone', sortable: false },
      { text: 'Email', value: 'user.email', sortable: false },
      { text: 'Secret', value: 'secret', sortable: false },
      { text: 'Method', value: 'method', sortable: false },
      { text: 'Status', value: 'state', sortable: false },
      { text: 'Created', value: 'created' },
      { text: 'Modified', value: 'modified' },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    list: [] as UserUpgradesInterface[],
    status: undefined
  }),
  methods: {
    getDataFromAPI () {
      this.tableLoading = true
      const filters = {} as FiltersVerificationsInterface
      if (this.tableSearch) {
        filters.search = this.tableSearch
      }
      if (this.status) {
        filters.status = this.status
      }
      if (this.ID) {
        filters.id = this.ID
      }
      UserUpgradesService.get(this.tableOptions, filters)
        .then(res => {
          if (res.data.body) {
            this.list = res.data.body.items ? res.data.body.items : []
            this.tableTotal = res.data.body.total ? res.data.body.total : 0
            this.pages = res.data.body.pages ? res.data.body.pages : 1
          }
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    approve (id: number) {
      UserUpgradesService.approve(id)
        .then(res => {
          if (res.data.body) {
            const index = this.list.findIndex((i: UserUpgradesInterface) => i.id === id)
            if (index > -1 && this.list[index] && res.data.body.state) {
              this.list[index].state = res.data.body.state
            }
          }
        })
    },
    decline (id: number) {
      UserUpgradesService.decline(id)
        .then(res => {
          if (res.data.body) {
            const index = this.list.findIndex((i: UserUpgradesInterface) => i.id === id)
            if (index > -1 && this.list[index] && res.data.body.state) {
              this.list[index].state = res.data.body.state
            }
          }
        })
    }
  },
  watch: {
    status (newV: UserUpgradeState | undefined, oldV: UserUpgradeState | undefined) {
      if (newV !== oldV) {
        this.reloadList()
      }
    }
  }
})
